:root {
  --red: #ef233c;
  --gray: #D8DEE3;
  --darkGray: #323638;
  --btn-background: #F5F8FA;
  --btn-background-hover: #eceff1;
  --btn-border: #D5D8DA;
}

.App {
  display: block;
  color: #282828;
}

.blurbContainer {
  width: 25%;
  justify-content: center;
  border-right: 2px solid var(--gray);
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  line-height: 1.5;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 0 2rem;
  color: var(--darkGray);
}

.title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.titleSnack {
  color: var(--red);
}
.categories {
  margin-top: 1rem;
}
.categoryButton, .categoryButtonActive {
  padding: 0.2rem 0.4rem;
  margin: 0.2rem 0.2rem;
  background-color: #ffff;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
}

.categoryButton {
  border: 1px solid hsl(0, 0%, 80%);
}

.categoryButtonActive {
  border: 1.3px solid hsl(0, 0%, 30%);
}

.categoryButton:hover {
  cursor: pointer;
}

.selectLabel {
  padding-top: 0.5rem;
}

.restaurantContainer {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: 4rem;
  padding-right: 2rem;
}

.cityDropdown {
  padding: 1rem;
  font-size: 1rem;
  border-radius: 30px;
  color: white;
  font-weight: 700;
}

.keyContainer {
  line-height: 0.5rem;
}

@media only screen and (max-width: 2300px) {
  .restaurantContainer {
    width: 83%;
  }
}

@media only screen and (max-width: 2000px) {
  .restaurantContainer {
    width: 80%;
  }
}

@media only screen and (max-width: 1800px) {
  .restaurantContainer {
    width: 78%;
  }
}

@media only screen and (max-width: 1200px) {
  .restaurantContainer {
    width: 75%;
  }
}

@media only screen and (max-width: 1000px) {
  .title {
    font-size: 2rem;
  }

  .blurbContainer {
    width: 30%;
  }

  .restaurantContainer {
    width: 60%;
  }
}

@media only screen and (max-width: 700px) {
  .App {
    display: flex;
    flex-direction: column;
  }
  .title {
    font-size: 1.9rem;
  }

  .blurbContainer {
    background-color: white;
    width: 90%;
    border-bottom: 2px solid var(--gray);
    border-right: none;
    position: sticky;
    margin: 0 auto -3rem -1rem;
    padding-bottom: 1rem;
    text-align: center;
  }

  .restaurantContainer {
    width: 95%;
    margin-left: -1.5rem;
  }

  .description, .selectLabel {
    display: none;
  }

  .keyContainer {
    display: flex;
    font-size: 0.9rem;
    margin-bottom: -1rem;
  }

  .keyItem {
    margin: 1rem 0.5rem;
  }
}
