.container {
  border-bottom: 2px dashed var(--gray);
  width: 40%;
  margin: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 7px;
  border: 1px solid var(--btn-border);
  background-color: var(--btn-background);
  font-weight: 550;
  text-align: center;
}

.btn:hover {
  cursor: pointer;
  border: 1px solid var(--btn-border);
  background-color: var(--btn-background-hover);
}

.contactContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.contactTitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.contactDetails {
  background-color: var(--red);
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 10px;
  font-weight: 600;
  margin-top: 1rem;
  text-decoration: none;
}

.neighborhood {
  font-weight: 730;
  font-size: 0.8rem;
  margin-top: 0;
}

.name {
  color: var(--red);
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.2rem;
}

.item {
  color: var(--darkGray);
  font-weight: 600;
  margin: 0.8rem 0;
}

@media only screen and (max-width: 1000px) {
  .container {
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    width: 90%;
    margin: 1rem -0.5rem 1rem 0;
  }
}
